import React, { Component } from "react";
import Layout from "../components/layout";
import { Breadcrumbs, Post, Button } from "../components/styleguide";

const limit = 9;

class Tags extends Component {
  state = { limit };
  render() {
    const { data, common, breadcrumbs, posts } = this.props.pageContext;
    return (
      <Layout
        data={common}
        title={data.seoTitle}
        description={data.seoDescription}
        hrefLang={data.hrefLang}>
        <div className="main-container">
          <Breadcrumbs data={breadcrumbs} lang={common.lang} />
          <header className="section-header">
            <h1>
              <strong>{data.name}</strong>
            </h1>
            {data.opis && <p>{data.opis}</p>}
          </header>
          <div className="blog-container">
            <div className="blog-posts grid x3">
              {posts &&
                posts.length > 0 &&
                posts
                  .slice(0, this.state.limit)
                  .map((post) => (
                    <Post
                      primary
                      key={post.id}
                      data={post}
                      className="fade-in"
                      blogPath={common.blogLink}
                    />
                  ))}
            </div>

            {this.state.limit < posts.length && (
              <div className="show-more-button-container">
                <Button
                  type={"button"}
                  fill
                  content={common.translations[0].show_more}
                  size="lg"
                  onClick={() => this.setState({ limit: this.state.limit + 9 })}
                />
              </div>
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

export default Tags;
